export const handleSearchToggle = () => {

  jQuery('#search-toggle > button').on('click', function () {
    jQuery(this).prev().fadeIn(function () {
      jQuery(document).click(function (event) {
        var $target = jQuery(event.target);
        if ($target.hasClass('overlay')) {
          jQuery('.outer').fadeOut();
        }
      });
    });
  })

  jQuery('#search-toggle #search').on('click', function () {
    var searchTerm = jQuery('#search-toggle .search-bar').val();
    if (searchTerm != '') {
      window.location.href = '/?s=' + searchTerm;
    }
  });
  jQuery('#search-toggle .search-bar').on('keypress', function (e) {
    if (e.which == 13) {
      var searchTerm = jQuery('#search-toggle .search-bar').val();
      if (searchTerm != '') {
        window.location.href = '/?s=' + searchTerm;
      }
    }
  });
}